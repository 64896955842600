//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import {rzTabs, rzIconFlightStart, rzIconFlightEnd, rzSimpleTable, rzPreloader, rzAirlineIcon, rzButton, rzIconReturn} from 'razlet-ui';
import {stringDateToDate, firstZero} from '~/utils';
import timeSrc from '~/assets/img/time.webp';

export default {
  name: 'tablo',
  components: {
    rzTabs,
    rzIconFlightStart,
    rzIconFlightEnd,
    rzSimpleTable,
    rzPreloader,
    rzAirlineIcon,
    rzIconReturn,
    rzButton,
  },
  filters: {
    time(value) {
      const date = stringDateToDate(value);
      const hours = String(date.getHours());
      const minutes = String(date.getMinutes());

      const displayHours = hours.length < 2 ? `0${hours}` : hours;
      const displayMinutes = minutes.length < 2 ? `0${minutes}` : minutes;
      return `${displayHours}:${displayMinutes}`;
    },
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const now = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return {
      timeSrc,
      tabs: [
        {
          title: 'Вылет',
          value: 'departure',
          icon: 'rz-icon-flight-start',
        },
        {
          title: 'Прилет',
          value: 'arrival',
          icon: 'rz-icon-flight-end',
        },
      ],
      tableHead: [
        {name: 'Фактическое время'},
        {name: 'Направление'},
        {name: 'Авиакомпания'},
        {name: 'Статус'},
        {name: 'Запланированное время'},
      ],
      tableHeadMobile: [
        {name: 'Данные рейса'},
        {name: 'Время'},
      ],
      buttons: [
        {label: `Вчера (${firstZero(yesterday.getDate(), 2)}.${firstZero(yesterday.getMonth()+1, 2)})`, value: '-1day'},
        {label: `Сегодня (${firstZero(now.getDate(), 2)}.${firstZero(now.getMonth()+1, 2)})`, value: 'now'},
        {label: `Завтра (${firstZero(tomorrow.getDate(), 2)}.${firstZero(tomorrow.getMonth()+1, 2)})`, value: '1day'},
      ],
      activeTab: 'departure',
      selectedDay: 'now',
    };
  },
  computed: {
    ...mapGetters(['about']),
    city: function() {
      return this.about.title;
    },
  },
  methods: {
    getTablo() {
      this.$emit('refresh', {day: this.selectedDay, direction: this.activeTab});
    },
    switchTab(tab) {
      this.activeTab = tab;
      this.$emit('refresh', {day: this.selectedDay, direction: tab});
    },
    changeDay(day) {
      this.selectedDay = day;
      this.getTablo();
    },
  },
};
