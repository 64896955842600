//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {rzTabs, rzIconFlightStart, rzIconFlightEnd, rzSimpleTable, rzPreloader, rzAirlineIcon, rzButton, rzIconReturn} from 'razlet-ui';
import airport from '../assets/img/airport.webp';
import sale from '../assets/img/avia-sale.webp';
import schedule from '../assets/img/schedule.webp';
import tablo from '../assets/img/tablo.webp';
import airportHover from '../assets/img/airport_hover.webp';
import saleHover from '../assets/img/avia-sale_hover.webp';
import scheduleHover from '../assets/img/schedule_hover.webp';
import tabloHover from '../assets/img/tablo_hover.webp';

export default {
  name: 'main-block',
  components: {
    rzTabs,
    rzIconFlightStart,
    rzIconFlightEnd,
    rzSimpleTable,
    rzPreloader,
    rzAirlineIcon,
    rzIconReturn,
    rzButton,
  },
  props: {
    about: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buttons: [
        [
          {
            code: 'schedule',
            label: 'Расписание',
            icon: schedule,
            iconHover: scheduleHover,
            route: 'schedule',
          },
          {
            code: 'tablo',
            label: 'Онлайн табло',
            icon: tablo,
            iconHover: tabloHover,
            route: 'tablo',
          },
        ],
        [
          {
            code: 'about',
            label: 'Об аэропорте',
            icon: airport,
            iconHover: airportHover,
            route: 'about',
          },
          {
            code: 'sale',
            label: 'Продажа авиабилетов',
            icon: sale,
            iconHover: saleHover,
            route: 'sale',
          },
        ],
      ],
    };
  },
};
