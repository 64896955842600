//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {rzPreloader} from 'razlet-ui';
import { mapGetters, mapActions } from 'vuex';
import MainBlock from '~/views/main-block';
import TabloBlock from '~/views/tablo-block';
import ScheduleBlock from '~/views/schedule-block';
import seo from '~/mixins/seo';

export default {
  name: 'index',
  layout: 'index',
  components: {
    rzPreloader,
    MainBlock,
    TabloBlock,
    ScheduleBlock,
  },
  mixins: [seo],
  async asyncData ({ store, error }) {
    const content = await store.dispatch('getData', ``)
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e.message);
        error(e);
      });
    return {content};
  },
  data () {
    return {
      tablo: [],
      schedule: [],
      tabloLoading: true,
      scheduleLoading: true,
      content: {},
    };
  },
  computed: {
    ...mapGetters(['about']),
  },
  mounted() {
    this.loadTablo();
    this.loadSchedule();
  },
  methods: {
    ...mapActions(['getTablo', 'getSchedule']),
    loadTablo(data = {direction: 'departure'}) {
      this.tabloLoading = true;
      this.getTablo(data).then(res => {
        this.tablo = res;
        this.tabloLoading = false;
      });
    },
    loadSchedule(type = 'departure') {
      this.scheduleLoading = true;
      this.getSchedule({type}).then(res => {
        this.schedule = res;
        this.scheduleLoading = false;
      });
    },
  },
};
